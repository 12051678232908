import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"


function Reviews() {
	return (
        
        <div class="reviews">
            <div className="content">
            <div className="title">
                
                  <h1>See What Our Clients Say About Us</h1>

                  
            </div>
            <div className="review-sliders">
            
            <div class="elfsight-app-dc67d31d-3682-45c4-b7b0-4793c372d80c"></div>
         
            <div className="tripadvisor">
            <div class="elfsight-app-ca5a1e5b-56d5-430b-894c-d274c736d38b"></div>
            </div>
            </div>
            </div>
    
         </div>

    );
}

export default Reviews;